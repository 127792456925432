import React, { useState, useEffect } from 'react'

import api from '../services/api'
import filterIt from '../views/bar'

import { getClientId } from '../services/auth'
import { clearPromotions, getPromotions } from '../services/promotions'

export default function Promocoes() {
  const [produtos, setProdutos] = useState([])
  const [promo, setPromo] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    if (search.length > 3) {
      handleSubmit()
    }
  }, [search])

  async function onLoad() {
    try {
      const promos = await getPromotions()
      setPromo(promos)
    } catch (e) {
      console.log(e.response)
    }
  }

  async function limparPromocoes() {
    const confirm = window.confirm('Tem certeza que deseja limpar as promoções?')
    if (confirm) {
      try {
        await clearPromotions()
        alert('Sucesso')
        window.location = '/'
      } catch (e) {
        alert('Falhei')
      }
    }
  }

  async function handleSubmit() {
    const d = await api.get('/product/search/' + search.toUpperCase())
    setProdutos(d.data)
  }

  async function apagar(id) {
    const bora = window.confirm('Tem certeza que deseja apagar?')
    if (bora) {
      try {
        await api.delete('promocoes/' + id)
        window.location = '/promocoes'
      } catch (e) {
        console.log(e.response.data.message)
      }
    }
  }

  function edita(id) {
    window.location = 'promocao/edita/' + id
  }

  return (
    <div>
      <h1>Promoções</h1>
      <h2>Criar uma</h2>
      <form>
        <div className="form-group">
          <label>Procurar por Produto</label>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="form-control"
            id="produto"
            aria-describedby="emailHelp"
            placeholder=""
          ></input>
        </div>
      </form>
      <button className="btn btn-dark" onClick={handleSubmit}>
        Procurar
      </button>
      <div className="container mt-4">
        <div className="row justify-content-center" id="busca-alvo">
          {produtos.map((p) => (
            <div className="col-md-4 col-sm-6 col-12 mb-4" key={p.id}>
              <div className="card text-center p-3 shadow-sm">
                <p className="fw-bold">{p.title}</p>
                <img className="mx-auto" src={p.image} alt={p.title} height="75" width="75" />
                <p className="text-muted mt-2">R$ {p.price.toFixed(2)}</p>
                <a className="btn btn-sm btn-primary mt-3" href={'promocao/nova/' + p.id}>
                  Criar Promoção
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h3>Promoções</h3>
      {promo.map((p) => (
        <div key={p._id} className="alert alert-primary">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h4 className="fw-bold">{p.name}</h4>
                {p.type === 'cashback' && <p className="mb-1">Crédito de R$ {p.cashback} ao cliente</p>}
                {p.type === 'freeProduct' && (
                  <p className="mb-1">
                    Ao comprar {p.quantityRequired}, pode-se pegar {p.quantityFree} grátis
                  </p>
                )}

                <p className="text-muted small">
                  Válido de {new Date(p.validFrom).toLocaleString('pt-BR')} até{' '}
                  {new Date(p.validUntil).toLocaleString('pt-BR')}
                </p>

                {p.userConsumption && p.userConsumption.length > 0 && (
                  <p className="mb-1">{p.userConsumption.length} pessoas já estão participando dessa promoção</p>
                )}
              </div>
              <div className="col-md-4 text-md-end text-center">
                <button className="btn btn-warning me-2" onClick={() => edita(p._id)}>
                  Editar
                </button>
                <button className="btn btn-danger" onClick={() => apagar(p._id)}>
                  Apagar
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="my-2 d-flex justify-content-center mt-4">
        <button className="btn btn-danger" onClick={() => limparPromocoes()}>
          Limpar Promoções dos Usuários
        </button>
      </div>
    </div>
  )
}
