import React, { useEffect, useState } from 'react'
import AdmEventos from '../containers/Admin/AdmEventos'
import api from '../services/api'
import Loading from './Loading'
import { formatToBRL } from '../helpers/Numbers'

export default function GaveCredit() {
  const [credits, setCredits] = useState([])
  const [selectedEvent, setSelectedEvent] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (!selectedEvent.start) {
      setIsLoading(false)
      return
    }
    try {
      api.get(`credit/cashless/date/${selectedEvent.start}/${selectedEvent.end}`).then((d) => {
        if (d.data.length > 0) {
          setCredits(d.data.filter((c) => c.via === 'Manual'))
        }
      })
    } catch (error) {
      console.log(error)
    }

    setIsLoading(false)
  }, [selectedEvent])

  return (
    <div>
      <AdmEventos setSelectedEvent={setSelectedEvent} />
      <h1>Relatório de Créditos</h1>
      <p>Apeans créditos que não passaram pelo caixa</p>
      <div>
        {isLoading ? (
          <Loading size={60} />
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Admin</th>
                <th scope="col">Valor</th>
                <th scope="col">Comanda</th>
                <th scope="col">Nome</th>
                <th scope="col">Data</th>
                <th scope="col">Motivo</th>
              </tr>
            </thead>
            <tbody>
              {credits.length > 0 ? (
                <>
                  {credits.map((c) => {
                    return (
                      <tr key={c._id}>
                        <td>{c.adminName.split(' ')[0]}</td>
                        <td>{formatToBRL(c.amount)}</td>
                        <td>{c.tag}</td>
                        <td>{c.userId.name.split(' ')[0]}</td>
                        <td>
                          {new Date(c.createdAt).toLocaleString('pt-BR', {
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </td>
                        <td>{c.reason}</td>
                      </tr>
                    )
                  })}
                  <tr>
                    <td colSpan={6} style={{ textAlign: 'center' }}>
                      Total: {formatToBRL(credits.reduce((sum, c) => sum + c.amount, 0))}
                    </td>
                  </tr>
                </>
              ) : (
                <>N achei nada ;(</>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
