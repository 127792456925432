import React, { useEffect, useState, memo } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import AutoComplete from '../AutoComplete'
import LoaderButton from '../LoaderButton'
import { getCurrentEvent, isOkToAddPeople } from '../../services/events'
import { addUserToQueue, createNewUser, findUserByText, getUserById, isUserBlocked } from '../../services/users'
import Modal from '../../components/Modal'

import { getName } from '../../services/auth'

export default memo(function EntranceForm({ event }) {
  const [queryString, setQueryString] = useState('')
  const [userFound, setUserFound] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const [isVip, setIsVip] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [isFilled, setIsFilled] = useState(false)
  const [modal, setModal] = useState({})

  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }

  useEffect(() => {
    setUserInfo(userFound)
    setName(userFound.name)
  }, [userFound])

  const handleEditableChange = (e) => {
    const { id, value } = e.target
    if (value.length > 11 && !isChecked) return
    setUserInfo({
      ...userInfo,
      [id]: value // Update the corresponding field in userInfo
    })
  }

  function handleChangeName(e) {
    const fullName = e.target.value.trimStart()
    setName(fullName)
    setUserInfo({
      ...userInfo,
      name: fullName
    })
  }

  function clearUserInfo() {
    setUserFound({})
    setUserInfo({})
  }

  function setTel() {
    setUserInfo({ phone: queryString })
    setIsFilled(!isFilled)
  }

  function setCpf() {
    setUserInfo({ doc: queryString })
    setIsFilled(!isFilled)
  }

  function handleSumitForm() {
    submitForm()
      .then(() => {
        console.log('Deu certo')
      })
      .catch((e) => {
        console.log(e.message.error)
        setModal({
          show: true,
          type: 'danger',
          title: 'Problemas',
          msg: e.response.data.error,
          loading: true
        })
      })
  }
  async function submitForm() {
    setIsLoading(true)
    setModal({
      show: true,
      type: 'warning',
      title: 'Trabalhando',
      msg: 'Aguarde...',
      loading: true
    })
    // TODO - need to check if can add people to event
    if (true) {
      if (userFound._id) {
        if (true) {
          const data = {
            obs: isVip + '\n',
            ts: new Date(),
            eventId: event.id,
            userId: userFound._id,
            doc: userInfo.doc,
            phone: userInfo.phone,
            name
          }
          try {
            await addUserToQueue(data)
            setModal({
              show: true,
              type: 'success',
              title: 'Sucesso',
              msg: 'Deu tudo certo!',
              link: '/portaria',
              refresh: true
            })
          } catch (e) {
            if (e.response.data.error.startsWith('E11000 duplicate key error')) {
              alert('Já existe um usuário com esse documento cadastrado, por favor chame um ADM')
            } else {
              alert(`${e.response.data.error}`)
            }

            setModal({
              show: true,
              type: 'danger',
              title: 'Problemas',
              msg: 'Falhei ao adc na fila, informe esse telefone e cpf ao admin',
              link: '/portaria',
              refresh: true
            })
            setIsLoading(false)
            return false
          }
        } else {
          setModal({
            show: true,
            type: 'danger',
            title: 'Problemas',
            msg: 'Problemas com esse cadastro, chamar a Gerência',
            link: '/portaria',
            refresh: true
          })
          setIsLoading(false)
        }
      } else {
        const cretedUser = await createNewUser({ ...userInfo, isVip })
        const userObject = await getUserById(cretedUser.data._id)

        const localData = {
          obs: isVip + '\n',
          ts: new Date(),
          admin: getName(),
          eventId: event.id,
          userId: userObject._id,
          name: userInfo.name,
          doc: userInfo.doc,
          phone: userInfo.phone
        }
        await addUserToQueue(localData)
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Deu tudo certo!',
          link: '/portaria',
          refresh: true
        })
      }
    } else {
      setModal({
        show: true,
        type: 'danger',
        title: 'Problemas',
        msg: 'Desculpe, mas a casinha está lotada,\npeça para um ADMIN liberar mais pessoa',
        link: '/',
        refresh: true
      })
    }
    setQueryString('')
    setUserFound({})
    setUserInfo({})
    setIsVip('')
  }

  function validateForm() {
    return userInfo.phone && userInfo.phone.length > 8 && userInfo.doc && userInfo.doc.length > 8
  }

  return (
    <Container>
      <Modal modal={modal} />
      {/* Search bar */}
      <Row className="mt-3 mb-3 justify-content-center">
        <Col xs={12} md={6}>
          <AutoComplete
            id="documento"
            query={queryString}
            setQuery={setQueryString}
            setUserFound={setUserFound}
            filled={isFilled}
          />
          <div className="text-center">
            <Button variant="warning" className="mt-2 mx-2" onClick={clearUserInfo}>
              Limpar
            </Button>

            <Button variant="primary" className="mt-2 mx-2" onClick={setCpf}>
              CPF
            </Button>

            <Button variant="primary" className="mt-2 mx-2" onClick={setTel}>
              Telefone
            </Button>
          </div>
        </Col>
      </Row>
      {/* Form with locked and editable columns */}
      <div className="my-5 mx-2">
        <p>
          Documento estrangeiro <Form.Check type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
        </p>
      </div>

      <Row>
        {userFound.doc && (
          <div className="text-center">
            <div className="alert alert-warning">
              <p>
                Ao trocar as informações abaixo você está editando os dados do <strong>{userFound.name}</strong>
              </p>
            </div>
          </div>
        )}
        {/* Editable column */}
        <Col xs={12} md={6}>
          <Form>
            <Form.Group className="mb-3" controlId="doc">
              <Form.Label>CPF/Documento</Form.Label>
              <Form.Control
                type="number"
                placeholder="CPF"
                value={userInfo.doc || ''}
                onChange={handleEditableChange}
                maxLength={11}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                type="number"
                placeholder="Telefone"
                value={userInfo.phone || ''}
                onChange={handleEditableChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nome</Form.Label>
              <Form.Control type="text" placeholder="Nome" value={name} onChange={handleChangeName} />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      {/* Additional inputs and submit button */}
      <Row className="mt-3">
        <Col>
          <div>
            <label htmlFor="exampleInputEmail1">Tipo de Entrada</label>
            <select className="form-control form" onChange={(e) => setIsVip(e.target.value)}>
              {[
                'Consuma',
                'Entrada Seca',
                'VIP - Horário',
                'VIP - Aniversário',
                'VIP - Aniversário Acompanhante',
                'VIP - Instagram',
                'VIP - Instagram Acompanhante',
                'VIP - Sayuri',
                'VIP - DJ',
                'VIP - DJ Acompanhante',
                'VIP - Signo',
                'VIP - Drag',
                'VIP - Contrato'
              ].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <LoaderButton
            block
            size=""
            id="portaria-btn"
            className="my-3"
            // type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
            onClick={handleSumitForm}
          >
            Enviar
          </LoaderButton>
        </Col>
      </Row>
    </Container>
  )
})
