import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function SaveAdminModal({ showModal, setShowModal, modalTitle, modalMessage }) {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" href="/usuarios">
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
