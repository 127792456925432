import api from './api'

export async function getPromotions() {
  try {
    const response = await api.get('promotion')
    if (response.status === 200) {
      return response.data
    }
  } catch (e) {
    console.log(e.message)
    return []
  }
}

export async function getUserPromotions(userId) {
  const promo = await api.get('promotion/user/' + userId)
  return promo.data
}

export async function clearPromotions() {
  const response = await api.delete('promotion/userConsumption')
  return response.data
}
