import React, { useEffect, useState } from 'react'
import AdminForm from './../components/AdminForm'
import { createAdmin, deleteAdminById, getAdminById, updateAdmin } from '../services/admin'
import { Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { getUserRole } from '../helpers/Users'
import SaveAdminModal from '../components/Modal/SaveAdminModal'
import DeleteAdminModal from '../components/Modal/DeleteAdminModal'

const App = () => {
  const params = useParams()
  const [adminId, setAdminId] = useState(params.id)
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState(null)

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedAdmin(null)
  }

  const handleDeleteAdmin = async () => {
    deleteAdminById(params.id).then((data) => {
      setShowDeleteModal(false)
      window.location.href = '/usuarios'
    })
  }
  const [admin, setAdmin] = useState({
    name: '',
    document: '',
    email: '',
    pass: '',
    birth: '',
    photo: '',
    phone: '',
    roles: []
  })

  useEffect(() => {
    if (params.id) {
      getAdminById(params.id).then((admin) => {
        const x = getUserRole(admin)
        setAdminId(params.id)
        setAdmin({
          name: admin.name,
          document: admin.doc,
          email: admin.email,
          pass: '',
          birth: new Date(admin.birth),
          photo: admin.photo,
          phone: admin.phone,
          roles: x.replace(/\s/g, '').split(',')
        })
      })
    }
  }, [])

  const saveAdmin = (admin, adminId) => {
    ;<div>SaveAdminModal</div>
    if (adminId) {
      updateAdmin(admin, adminId)
        .then(() => {
          setShowModal(true)
          setModalTitle('Sucesso')
          setModalMessage('Atualizado com sucesso!')
        })
        .catch((error) => {
          setModalMessage(`Error saving admin:  ${error.response.data.error}`)
          setShowModal(true)
          setModalTitle('Falha')
        })
    } else {
      createAdmin(admin)
        .then(() => {
          setShowModal(true)
          setModalTitle('Sucesso')
          setModalMessage('Criado com sucesso!')
        })
        .catch((error) => {
          setShowModal(true)
          setModalTitle('Falha')
          setModalMessage(`Error saving admin:  ${error.response.data.error}`)
          console.log('Error saving admin:', error)
        })
    }
  }

  return (
    <div>
      <h1>{params.id ? <>Edição de ADM</> : <>Criação de ADM</>}</h1>
      <AdminForm adminData={admin} onSave={saveAdmin} setShowDeleteModal={setShowDeleteModal} adminId={adminId} />
      <SaveAdminModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={modalTitle}
        modalMessage={modalMessage}
      />
      <DeleteAdminModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={handleDeleteAdmin}
        adminName={admin.name}
      />
      <div>
        <Button variant="btn btn-success" href="/usuarios">
          Voltar
        </Button>
      </div>
    </div>
  )
}

export default App
