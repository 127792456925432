import React, { useState, useEffect } from 'react'
import api from '../services/api'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { ListItemsReport, ListItemsReportUsers } from '../components/ListItemsReport'
import AdmEventos from './Admin/AdmEventos'
import { Link } from 'react-router-dom'
import { formatToBRLWithSymbol } from '../helpers/Numbers'

const moment = require('moment')

export default function Relatorios() {
  const [today] = useState(new Date())
  const [priorDate] = useState(new Date().setDate(today.getDate() - 1))
  const [inicio, setInicio] = useState(priorDate)
  const [fim, setFim] = useState(today)
  const [caixaArrecadado, setCaixaArrecadado] = useState([])
  const [gaveCredit, setGaveCredit] = useState([])
  const [barVendido, setBarVendido] = useState([])
  const [usuariosEvento, setUsuariosEvento] = useState([])
  const [totalPayedByUsers, setTotalPayedByUsers] = useState(0)
  const [selectedEvent, setSelectedEvent] = useState('')
  const [selledProductCount, setSelledProductCount] = useState([])
  const [perdaData, setPerdaData] = useState([])

  async function perda() {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    const p = await api.get(`consumption/total/lost/${i}/${f}`)
    setPerdaData(p.data)
  }

  async function usuarios() {
    const user = await api.get('credit/users')
    if (user.data) {
      setUsuariosEvento(user.data)
    }
  }

  function isLongerThan30days(inicio, fim) {
    const d1 = moment(inicio)
    const d2 = moment(fim)
    return d2.diff(d1) / 60 / 60 / 24 / 1000 > 90
  }

  async function creditos() {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    const p = await api.get(`transaction/total/credit/${i}/${f}`)
    setGaveCredit(p.data)
  }

  async function caixa() {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    if (isLongerThan30days(inicio, fim)) {
      alert('So é permitido verificar num periodo de 30 dias')
      alert('Mude a data')
      return false
    }
    const p = await api.get(`cash-register/cash/total/transactions/${i}/${f}`)
    setCaixaArrecadado(p.data)
  }

  async function bar() {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    if (isLongerThan30days(inicio, fim)) {
      alert('So é permitido verificar num periodo de 30 dias')
      alert('Mude a data')
      return false
    }
    const p = await api.get(`consumption/total/selled/admin/${i}/${f}`)
    setBarVendido(p.data)
  }

  async function contagemVendidos() {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    if (isLongerThan30days(inicio, fim)) {
      alert('So é permitido verificar num periodo de 30 dias')
      alert('Mude a data')
      return false
    }
    const p = await api.get(`consumption/total/selled/product/${i}/${f}`)
    setSelledProductCount(p.data)
  }

  async function arrecadadoVendidos() {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    if (isLongerThan30days(inicio, fim)) {
      alert('So é permitido verificar num periodo de 30 dias')
      alert('Mude a data')
      return false
    }
    const p = await api.get(`consumption/total/selled/product/${i}/${f}`)

    let res = ''
    let total = 0

    const target = document.getElementById('tVendidos')
    target.innerHTML = `
            <h5>Total vendido</h5>
            Total arrecadado: R$ <b>${p.data.reduce((acc, item) => item.totalValue + acc, 0)}</b>
            <hr />
        `
  }

  async function recargas() {
    try {
      const i = new Date(inicio).toISOString()
      const f = new Date(fim).toISOString()
      if (isLongerThan30days(inicio, fim)) {
        alert('So é permitido verificar num periodo de 30 dias')
        alert('Mude a data')
        return false
      }
      const p = await api.get(`cash-register/total/cash/${i}/${f}`)
      setTotalPayedByUsers(p.data)
    } catch (e) {
      console.log(e)
      console.log(e.response)
    }
  }

  useEffect(() => {
    if (!selectedEvent.start) return
    setInicio(new Date(selectedEvent.start))
    setFim(new Date(selectedEvent.end))
  }, [selectedEvent])

  return (
    <div>
      <h1>Números</h1>
      <h3>Período</h3>
      <p>Lembra-se que os dados abaixo serão relacioandos ao período selecionado</p>
      <form>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Início
          </label>
          <div className="col-sm-10">
            <DatePicker
              id="inicio"
              selected={inicio}
              onChange={(date) => setInicio(date)}
              timeInputLabel="Horário:"
              dateFormat="dd/MM/yyyy h:mm aa"
              className="form-control"
              showTimeInput
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputPassword" className="col-sm-2 col-form-label">
            Fim
          </label>
          <div className="col-sm-10">
            <DatePicker
              id="fim"
              selected={fim}
              onChange={(date) => setFim(date)}
              timeInputLabel="Horário:"
              dateFormat="dd/MM/yyyy h:mm aa"
              className="form-control"
              showTimeInput
            />
          </div>
        </div>
        <AdmEventos setSelectedEvent={setSelectedEvent} />
      </form>

      <hr />
      <div>
        <h3>
          Total arrecado por Caixa |{' '}
          <button id="totalCaixa" onClick={caixa} className="btn btn-primary">
            {' '}
            -&gt;{' '}
          </button>
        </h3>
        <div id="caixaArrecadado">
          {JSON.stringify()}
          {caixaArrecadado.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Caixa</th>
                  <th scope="col">Admin</th>
                  <th scope="col">Dinheiro</th>
                  <th scope="col">Cartao</th>
                  <th scope="col">Total</th>
                  <th scope="col">Sangria</th>
                </tr>
              </thead>
              <tbody>
                {caixaArrecadado.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item._id}</td>
                      <td>{item.adminName}</td>
                      <td>{item.totalCash}</td>
                      <td>{item.totalCard}</td>
                      <td>{item.totalCash + item.totalCard}</td>
                      <td>{item.depletion}</td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Total</td>
                  <td>-------------</td>
                  <td style={{ fontWeight: 'bold' }}>
                    {caixaArrecadado.reduce((acc, item) => acc + item.totalCash, 0)}
                  </td>
                  <td style={{ fontWeight: 'bold' }}>
                    {caixaArrecadado.reduce((acc, item) => acc + item.totalCard, 0)}
                  </td>
                  <td style={{ fontWeight: 'bold' }}>
                    {caixaArrecadado.reduce((acc, item) => acc + item.totalCard + item.totalCash, 0)}
                  </td>
                  <td style={{ fontWeight: 'bold' }}>
                    {caixaArrecadado.reduce((acc, item) => acc + item.depletion, 0)}
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>

      <div>
        <h3>
          Total de Créditos Dados (sem passar pelo caixa)
          <button id="totalCaixa" onClick={creditos} className="my-3 mx-5 btn btn-primary">
            {' '}
            -&gt;{' '}
          </button>
        </h3>
        <div id="caixaArrecadado">
          <ListItemsReport items={gaveCredit} />
          <p className="my-2">
            <Link to="/gaveCredits">Ver em detalhes</Link>
          </p>
        </div>
      </div>

      <div>
        <h3>
          Comanda de Perda
          <button id="totalPerda" onClick={perda} className="my-3 mx-5 btn btn-primary">
            {' '}
            -&gt;{' '}
          </button>
        </h3>
        <div id="perda">
          {perdaData.length > 0 && (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Admin</th>
                    <th scope="col">Produto</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {perdaData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.adminName}</td>
                        <td>{item.products.title}</td>
                        <td>{item.products.quantity}</td>
                        <td>{formatToBRLWithSymbol(item.totalAmount)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              R$ {formatToBRLWithSymbol(perdaData.reduce((acc, item) => item.totalAmount + acc, 0))}
            </>
          )}
        </div>
      </div>

      <hr />
      <div>
        <h3>
          Total arrecado por Pessoa do Bar |{' '}
          <button id="totalBar" onClick={bar} className="btn btn-primary">
            -&gt;
          </button>
        </h3>
        <div id="caixaArrecadado">
          {barVendido.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Pessoa</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {barVendido.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item._id}</td>
                      <td>{formatToBRLWithSymbol(item.totalValue)}</td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Total</td>
                  <td style={{ fontWeight: 'bold' }}>
                    {formatToBRLWithSymbol(barVendido.reduce((acc, item) => acc + item.totalValue, 0))}
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>

      <hr />
      <div>
        <h3>
          Usuários que vieram/estão no evento |{' '}
          <button id="userKit" onClick={usuarios} className="btn btn-primary">
            -&gt;
          </button>
        </h3>
        <div id="usuarios">
          <ListItemsReportUsers items={usuariosEvento} />
        </div>
      </div>
      <div>
        <h3>
          Contagem de produtos vendidos |{' '}
          <button id="contagemVendidos" onClick={contagemVendidos} className="btn btn-primary">
            -&gt;
          </button>
        </h3>
        <div id="cVendidos">
          {selledProductCount.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Produto</th>
                  <th scope="col">Quantidade</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                {selledProductCount.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item._id}</td>
                      <td>{item.totalQuantity}</td>
                      <td>{item.totalValue}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div>
        <h3>
          Total arrecadado pelos produtos |{' '}
          <button id="totalArrecProd" onClick={arrecadadoVendidos} className="btn btn-primary">
            -&gt;
          </button>
        </h3>
        <div id="tVendidos"></div>
      </div>

      <div>
        <h3>
          Total pago por usuários |{' '}
          <button id="totalPgUsuarios" onClick={recargas} className="btn btn-primary">
            -&gt;
          </button>
        </h3>
        <div id="tRecarga">
          {totalPayedByUsers > 0 && (
            <>
              <p>Total pago por usuários: R$ {totalPayedByUsers}</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
